
.App{
    height: 100%;
    max-width: 450px;
    margin: 0 auto;
    position: relative;
    background: white;
    box-shadow: 0 0 20px 0px rgba(0,0,0,0.1);
}

.page{
    display: grid;
    grid-template-rows: 60px 1fr;
    height: 100%;
    .content{
        overflow: auto;
        position: relative;
    }
}

.catchesGrid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    grid-auto-rows: 80px;
    div{
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.AddCatch{
    height: 100%;
    background: white;
    .capture{
        background: black;
        min-height: 100%;
    }
    .record{
        color:#021621;
        .imgPreview{
            max-height: 70vh;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .captureForm{
            border-radius: 20px 20px 0 0;
            overflow: hidden;
            padding: 1.3rem;
            transform: translateY(-24px);
            background: white;
        }
    }
}

.LoadingDots{
    width: 300px;
    height: 200px;
    &.small {
        width: 40px;
        height: 50px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    .container{
        width: 150px;
        &.small {
            width: 100%;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dot{
            width: 20px;
            height: 20px;
            &.small{
                width: 8px;
                height: 8px;
            }
            border-radius: 50%;
            background-color: black;
            &.one{
                animation: pulse .4s ease 0s infinite alternate;
            }
            &.two{
                animation: pulse .4s ease .2s infinite alternate;
            }
            &.three{
                animation: pulse .4s ease .4s infinite alternate;
            }
        }
    }

}


.biteTimeFeaturedData {
    position: absolute;
    top: 45%;
    left: 45%;
}


@keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .25;
      transform: scale(.75);
    }
}
