@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html, body{
	font-size: 16px;
	height: 100%;
}

#root{
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #E5E5E5;
}

img{
  	max-width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
